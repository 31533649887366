import React, { useEffect } from 'react'
import  Button  from "@mui/material/Button";


const ButtonControl = (props) =>  {
    const {id, text, size, color, variant, onClick, style, customClass, innercomponent, disabled, startIcon, endIcon, ...other } = props;
   
    const defautlSx = { width: "100px", margin: "10px" };

    useEffect(() => {}, [other, innercomponent]);

    return (
      <Button
        id={id}
        variant={variant || "contained"}
        size={size || "large"}
        color={color || "primary"}
        onClick={onClick}
        sx={style == null ? defautlSx : style}
        disabled={disabled}
        className={customClass}
        startIcon={startIcon}
        endIcon={endIcon}
        {...other}
      >
        {text}
        {innercomponent}
      </Button>
    );
}

export default ButtonControl;