import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fireEvent } from "../../../../../modules/Events/eventSlice";
import { GameCenterViewContext } from "../../../../../utility/context";

import { URLS } from "../../../../../configs/api_urls";
import { httpGet, httpPut, httpPost } from "../../../../../utility/request";

import EditAssetSkeleton from "../../../Skeletons/edit-asset-skeleton.component";
import ImageProcessor from "../../../../../components/ImageProcessor/image-processor.component";
import Controls from "../../../../../components/Controls/Controls";
import { FormControl, Chip } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FontAwesome from "../../../../../components/Icons/fontawesome";
import { faCircleInfo, faMinus } from "@fortawesome/free-solid-svg-icons";

import moment from "moment/moment";

import "./edit-asset.styles.scss";

const intialFixedPricing = {
  assetPricingFixedId: 0,
  timeInMinutes: 0,
  price: 0,
};

const intialInterveledPricing = [
  {
    assetPricingId: -1,
    timeInMinutes: 0,
    price: 0,
  },
  {
    assetPricingId: -2,
    timeInMinutes: 0,
    price: 0,
  },
  {
    assetPricingId: -3,
    timeInMinutes: 0,
    price: 0,
  },
];

const intialWeeklyPricing = [
  {
    assetPricingWeeklyId: -1,
    weekday: "Monday",
    price: 0,
  },
  {
    assetPricingWeeklyId: -2,
    weekday: "Tuesday",
    price: 0,
  },
  {
    assetPricingWeeklyId: -3,
    weekday: "Wednesday",
    price: 0,
  },
  {
    assetPricingWeeklyId: -4,
    weekday: "Thursday",
    price: 0,
  },
  {
    assetPricingWeeklyId: -5,
    weekday: "Friday",
    price: 0,
  },
  {
    assetPricingWeeklyId: -6,
    weekday: "Saturday",
    price: 0,
  },
  {
    assetPricingWeeklyId: -7,
    weekday: "Sunday",
    price: 0,
  },
];

const EditAsset = ({ selectedAsset, closeEdit }) => {
  var dispatch = useDispatch();
  const gameCenterDetails = useContext(GameCenterViewContext);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedAssetItem, setSelectedAssetItem] = useState(selectedAsset);
  const [selectedAssetItemOriginal, setSelectedAssetItemOriginal] =
    useState(selectedAsset);
  const [assetTypes, setAssetTypes] = useState([]);
  const [instanceStandardGames, setInstanceStandardGames] = useState([]);

  const [newItemId, setNewItemId] = useState(-4);

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "save-asset-details") {
      if (selectedAsset.assetId > 0) {
        updateAsset();
      } else {
        createAsset();
      }

      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  //#region  API calls
  const fetchAssetDetails = async() => {
    setIsLoading(true);
    const url1 = URLS.ASSET.GET_ASSET_DETAILS.replace(
      "#ID#",
      selectedAsset.assetId
    );
    const url2 = URLS.GAME_CENTERS.GET_INSTANCE_STANDARD_GAMES.replace(
      "#ID#",
      gameCenterDetails.GameCenter.applicationInstanceId
    );
    const url3 = URLS.ASSET.ASSET_TYPES_LIST;

    if (selectedAsset.assetId > 0) {
     await Promise.all([httpGet(url1), httpGet(url2), httpGet(url3)])
        .then(function (results) {
          setSelectedAssetItem(results[0].data);
          setSelectedAssetItemOriginal(results[0].data);

          var standargames = results[1].data.map((game) => ({
            id: game.standardGameId,
            title: game.standardGameName,
          }));
          setInstanceStandardGames(standargames);

          var assetTypes = results[2].data.map((type) => ({
            id: type.assetTypeId,
            title: type.assetTypeName,
          }));
          setAssetTypes(assetTypes);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else 
    {
      Promise.all([httpGet(url2), httpGet(url3)])
        .then(function (results) {

          var standargames = results[0].data.map((game) => ({
            id: game.standardGameId,
            title: game.standardGameName,
          }));
          setInstanceStandardGames(standargames);

          var assetTypes = results[1].data.map((type) => ({
            id: type.assetTypeId,
            title: type.assetTypeName,
          }));
          setAssetTypes(assetTypes);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const updateAsset = async () => {
    // API CAll here
    await httpPost(URLS.ASSET.UPDATE_ASSET, selectedAssetItem)
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-asset-list" }));
          closeEdit();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
    // close after saving
  };

  const createAsset = async () => {
    // API CAll here
    await httpPost(URLS.ASSET.CREATE_ASSET, selectedAssetItem)
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-asset-list" }));
          closeEdit();
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      });
    // close after saving
  };

  //#endregion  API calls

  const handleSlotsChange = (startTime, endTime, duration) => {
    //for displaying need to change values from utc to application timezone
    var timeZone = gameCenterDetails.GameCenter.timeZone;
    var momentStartTime = moment.utc(startTime).tz(timeZone);
    var momentEndTime = moment.utc(endTime).tz(timeZone);
    var slotDurationVal = Number(duration);

    var isUpdatingSlots = getIsUpdatingSlotes(startTime, endTime, duration);

    const durationDiff = moment.duration(momentEndTime.diff(momentStartTime));
    const openHours = durationDiff.asHours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const number_of_slots = Math.floor(openHours / (slotDurationVal / 60));

    const availableSlots = [...Array(number_of_slots)];

    const newSlots = availableSlots.map((el, index) => {
      let stime = momentStartTime.clone();
      let etime = momentStartTime.clone();
      let slotStartTime = stime.add(slotDurationVal * index, "minutes");
      let slotEndTime = etime.add(slotDurationVal * (index + 1), "minutes");

      if (etime.diff(momentEndTime) > 0) return null;
      return {
        slotStartTime,
        slotEndTime,
        isDisabled: false,
        slotDuration: Number(duration),
        slotDisplayText: `${slotStartTime.format(
          "h:mm A"
        )} - ${slotEndTime.format("h:mm A")} `,
      };
    });

    setSelectedAssetItem({
      ...selectedAssetItem,
      slotsStartTime: momentStartTime,
      slotsEndTime: momentEndTime,
      slotDuration: slotDurationVal,
      assetTimeSlots: newSlots,
      isUpdatingSlots: isUpdatingSlots,
    });
  };

  const handlePricingTypeIdChange = (e) => {
    var pricingTypeId = Number(e.target.value);
    // set initial values if selected pricing changes
    if (pricingTypeId == 1 && selectedAssetItemOriginal.pricingId != 1) {
      setSelectedAssetItem({
        ...selectedAssetItem,
        pricingId: pricingTypeId,
        fixedPricing: intialFixedPricing,
      });
    } else if (pricingTypeId == 2 && selectedAssetItemOriginal.pricingId != 2) {
      setSelectedAssetItem({
        ...selectedAssetItem,
        pricingId: pricingTypeId,
        pricings: intialInterveledPricing,
      });
    } else if (pricingTypeId == 3 && selectedAssetItemOriginal.pricingId != 3) {
      setSelectedAssetItem({
        ...selectedAssetItem,
        pricingId: pricingTypeId,
        weeklyPricing: intialWeeklyPricing,
      });
    } else {
      setSelectedAssetItem({
        ...selectedAssetItem,
        pricingId: pricingTypeId,
      });
    }
  };

  // interveled pricing events

  const handleInterveledPricingTimeChange = (time, assetPricingId) => {
    var pricingsVal = selectedAssetItem.pricings.map((pricing) =>
      pricing.assetPricingId === assetPricingId
        ? { ...pricing, timeInMinutes: time }
        : pricing
    );
    setSelectedAssetItem({
      ...selectedAssetItem,
      pricings: pricingsVal,
    });
  };

  const handleInterveledPricingPriceChange = (price, assetPricingId) => {
    var pricingsVal = selectedAssetItem.pricings.map((pricing) =>
      pricing.assetPricingId === assetPricingId
        ? { ...pricing, price: price }
        : pricing
    );
    setSelectedAssetItem({
      ...selectedAssetItem,
      pricings: pricingsVal,
    });
  };

  const removeInterveledPricing = (assetPricingId) => {
    var pricingsVal = selectedAssetItem.pricings.filter(
      (pricing) => pricing.assetPricingId != assetPricingId
    );
    setSelectedAssetItem({
      ...selectedAssetItem,
      pricings: pricingsVal,
    });
  };

  // add feature
  const addInterveledPricingPoint = () => {
    var newPricingPoint = {
      assetPricingId: newItemId,
      timeInMinutes: 0,
      price: 0,
    };

    var pricingsVal = [...selectedAssetItem.pricings, { ...newPricingPoint }];
    setSelectedAssetItem({
      ...selectedAssetItem,
      pricings: pricingsVal,
    });
    setNewItemId(newItemId - 1);
  };

  // weekly pricing change handler
  const handleWeeklyPricingPriceChange = (price, assetPricingWeeklyId) => {
    var weeeklyPricingsVal = selectedAssetItem.weeklyPricing.map((pricing) =>
      pricing.assetPricingWeeklyId === assetPricingWeeklyId
        ? { ...pricing, price: price }
        : pricing
    );
    setSelectedAssetItem({
      ...selectedAssetItem,
      weeklyPricing: weeeklyPricingsVal,
    });
  };

  // handle offer expiry date change
  const handleOfferExpiryDateChange = (event, assetOfferMapId) => {
    var assetOfferMapVal = selectedAssetItem.assetOffers.map((offer) =>
      offer.assetOfferMapId === assetOfferMapId
        ? { ...offer, expiryDate: event }
        : offer
    );
  setSelectedAssetItem({
    ...selectedAssetItem,
    assetOffers: assetOfferMapVal,
  });}; 

  const getIsUpdatingSlotes = (startTime, endTime, duration) => {
    var originalStartTime = moment(selectedAssetItemOriginal.slotsStartTime);
    var originalEndTime = moment(selectedAssetItemOriginal.slotsEndTime);
    var originalDuration = selectedAssetItemOriginal.slotDuration;
    if (
      originalStartTime.diff(startTime) !== 0 ||
      originalEndTime.diff(endTime) !== 0 ||
      duration != originalDuration
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    fetchAssetDetails();
  }, [selectedAsset]);

  return (
    <div>
      {isLoading && (
        <div>
          <EditAssetSkeleton></EditAssetSkeleton>
        </div>
      )}
      {!isLoading && (
        <div>
          <div className="display-grid">
            <div className="row">
              <div className="col-md-3">
                <ImageProcessor
                  alt="asset_image"
                  style={{ height: "100%", borderRadius: "15px" }}
                  imageUrl={selectedAssetItem.pictureUrl}
                  uploadUrl={URLS.FILES.FACILITES_IMAGES}
                  setImageData={(e) => {
                    setSelectedAssetItem({
                      ...selectedAssetItem,
                      pictureUrl: e.imageFullUrl,
                    });
                  }}
                ></ImageProcessor>
              </div>
              <div className="col-md-9">
                <div className="display-flex w-100">
                  <div className="w-50">
                    <label className="field-title">Game</label>
                    <Controls.SelectControl
                      name="gameType"
                      style={{ width: "95%" }}
                      showDefaultOption={true}
                      defaultOptionText="None"
                      options={instanceStandardGames}
                      value={selectedAssetItem.standardGameId}
                      onChange={(e) =>
                        setSelectedAssetItem({
                          ...selectedAssetItem,
                          standardGameId: e.target.value,
                        })
                      }
                    ></Controls.SelectControl>
                  </div>
                  <div className="display-grid w-50">
                    <label className="field-title">Asset Type</label>

                    <Controls.SelectControl
                      name="assetType"
                      style={{ width: "95%" }}
                      showDefaultOption={true}
                      defaultOptionText="None"
                      options={assetTypes}
                      value={selectedAssetItem.assetTypeId}
                      onChange={(e) =>
                        setSelectedAssetItem({
                          ...selectedAssetItem,
                          assetTypeId: e.target.value,
                        })
                      }
                    ></Controls.SelectControl>
                  </div>
                </div>
                <div className="display-grid mt-20">
                  <label className="field-title">Title</label>
                  <FormControl>
                    <Controls.InputControl
                      name="title"
                      other={{ placeholder: "Asset name" }}
                      style={{ width: "100%", marginTop: "5px" }}
                      onChange={(e) =>
                        setSelectedAssetItem({
                          ...selectedAssetItem,
                          assetName: e.target.value,
                        })
                      }
                      value={selectedAssetItem.assetName}
                      //error={validation.planName}
                    ></Controls.InputControl>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="edit-section-margin">
              <label className="field-title">Description</label>
              <FormControl>
                <Controls.InputControl
                  name="title"
                  isMultiLine={true}
                  rows={3}
                  other={{ placeholder: "Asset name" }}
                  style={{ width: "100%", marginTop: "5px" }}
                  onChange={(e) =>
                    setSelectedAssetItem({
                      ...selectedAssetItem,
                      assetDescription: e.target.value,
                    })
                  }
                  value={selectedAssetItem.assetDescription}
                  //error={validation.planName}
                ></Controls.InputControl>
              </FormControl>
            </div>
            <div className="edit-section-margin">
              <label className="field-title">Time Slots</label>
              <div className="row mt-10">
                <div className="col-md-4">
                  <Controls.TimePickerControl
                    label={"Start time"}
                    id={"starttime_" + selectedAssetItem.assetId}
                    name="startTime"
                    value={selectedAssetItem.slotsStartTime}
                    onChange={(value) => {
                      handleSlotsChange(
                        value,
                        selectedAssetItem.slotsEndTime,
                        selectedAssetItem.slotDuration
                      );
                    }}
                  ></Controls.TimePickerControl>
                </div>
                <div className="col-md-4">
                  <Controls.TimePickerControl
                    id={"starttime_" + selectedAssetItem.assetId}
                    label={"End time"}
                    name="startTime"
                    value={selectedAssetItem.slotsEndTime}
                    onChange={(value) => {
                      handleSlotsChange(
                        selectedAssetItem.slotsStartTime,
                        value,
                        selectedAssetItem.slotDuration
                      );
                    }}
                  ></Controls.TimePickerControl>
                </div>
                <div className="col-md-4">
                  <Controls.InputControl
                    name="duration"
                    label={"Duration"}
                    other={{
                      type: "number",
                      placeholder: "Duration",
                    }}
                    minNumber={0}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      handleSlotsChange(
                        selectedAssetItem.slotsStartTime,
                        selectedAssetItem.slotsEndTime,
                        e.target.value
                      );
                    }}
                    value={selectedAssetItem.slotDuration}
                    // error={validation.bankName}
                  ></Controls.InputControl>
                </div>
              </div>

              <div className="mt-20">
                {selectedAssetItem.assetTimeSlots &&
                  selectedAssetItem.assetTimeSlots.map((slot, index) => (
                    <Chip
                      variant="outlined"
                      key={`slot-${index}`}
                      label={slot.slotDisplayText}
                      style={{
                        height: "40px",
                        margin: "5px 2px",
                        fontSize: "16px",
                      }}
                      clickable
                      //handleClick={handleDisableSlot}
                    />
                  ))}
              </div>
            </div>
            <div className="edit-section-margin">
              <label className="field-title">Pricing</label>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedAssetItem.pricingId}
                  onChange={(e) => handlePricingTypeIdChange(e)}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Fixed"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Interveled"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Weekly"
                  />
                </RadioGroup>
              </FormControl>
              {selectedAssetItem.pricingId == 1 && (
                <div>
                  <div className="display-flex">
                    <FontAwesome
                      icon={faCircleInfo}
                      iconSize={"20px"}
                      className={"info-icon-margin"}
                    ></FontAwesome>

                    <span className="font-italic text-sub-info">
                      You have selected Fixed pricing. You can set price for
                      given time in miutes. Your customer's bills will be
                      calculated with respect to these added points.
                    </span>
                  </div>
                  <div className="row mt-10">
                    <div className="col-md-6">
                      <Controls.InputControl
                        label={"Time in minutes"}
                        name="time"
                        other={{
                          type: "number",
                          placeholder: "Time in minutes",
                        }}
                        minNumber={0}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setSelectedAssetItem({
                            ...selectedAssetItem,
                            fixedPricing: {
                              timeInMinutes: Number(e.target.value),
                              price: selectedAssetItem.fixedPricing.price,
                              assetPricingFixedId:
                                selectedAssetItem.fixedPricing
                                  .assetPricingFixedId,
                            },
                          });
                        }}
                        value={selectedAssetItem.fixedPricing.timeInMinutes}
                        // error={validation.bankName}
                      ></Controls.InputControl>
                    </div>
                    <div className="col-md-6">
                      <Controls.InputControl
                        label={"Price"}
                        name="price"
                        other={{
                          type: "number",
                          placeholder: "Price (Rs.)",
                        }}
                        minNumber={0}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setSelectedAssetItem({
                            ...selectedAssetItem,
                            fixedPricing: {
                              timeInMinutes:
                                selectedAssetItem.fixedPricing.timeInMinutes,
                              price: Number(e.target.value),
                              assetPricingFixedId:
                                selectedAssetItem.fixedPricing
                                  .assetPricingFixedId,
                            },
                          });
                        }}
                        value={selectedAssetItem.fixedPricing.price}
                        // error={validation.bankName}
                      ></Controls.InputControl>
                    </div>
                  </div>
                </div>
              )}
              {selectedAssetItem.pricingId == 2 && (
                <div>
                  <div className="display-flex">
                    <FontAwesome
                      icon={faCircleInfo}
                      iconSize={"20px"}
                      className={"info-icon-margin"}
                    ></FontAwesome>

                    <span className="font-italic text-sub-info">
                      You have selected interveled pricing. You can add multiple
                      price values with respect to time. Your customer's bills
                      will be calculated with respect to these added points.
                      <label
                        onClick={(e) => addInterveledPricingPoint()}
                        className="add-pricing-point"
                        href=""
                        style={{ marginLeft: "4px" }}
                      >
                        Add Pricing point
                      </label>
                    </span>
                  </div>
                  <div>
                    {selectedAssetItem.pricings.map((item, index) => {
                      return (
                        <div key={item.assetPricingId} className="row mt-10">
                          <div className="col-md-5">
                            <Controls.InputControl
                              name="time"
                              other={{
                                type: "number",
                                placeholder: "Time in minutes",
                              }}
                              minNumber={0}
                              style={{ width: "100%" }}
                              onChange={(e) => {
                                handleInterveledPricingTimeChange(
                                  e.target.value,
                                  item.assetPricingId
                                );
                              }}
                              value={item.timeInMinutes}
                              // error={validation.bankName}
                            ></Controls.InputControl>
                          </div>
                          <div className="col-md-5">
                            <Controls.InputControl
                              name="price"
                              other={{
                                type: "number",
                                placeholder: "Price (Rs.)",
                              }}
                              minNumber={0}
                              style={{ width: "100%" }}
                              onChange={(e) => {
                                handleInterveledPricingPriceChange(
                                  e.target.value,
                                  item.assetPricingId
                                );
                              }}
                              value={item.price}
                              // error={validation.bankName}
                            ></Controls.InputControl>
                          </div>
                          <div className="col-md-2">
                            <Controls.ButtonControl
                              text={""}
                              onClick={(e) => {
                                removeInterveledPricing(item.assetPricingId);
                              }}
                              style={{
                                height: "80%",
                                background: "lightgray",
                                marginTop: "5px",
                              }}
                              innercomponent={
                                <FontAwesome icon={faMinus}></FontAwesome>
                              }
                            ></Controls.ButtonControl>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {selectedAssetItem.pricingId == 3 && (
                <div>
                  <div className="display-flex">
                    <FontAwesome
                      icon={faCircleInfo}
                      iconSize={"20px"}
                      className={"info-icon-margin"}
                    ></FontAwesome>

                    <span className="font-italic text-sub-info">
                      You have selected Weekly pricing. You can add price
                      according to the day of the week. Your customer's bills
                      will be calculated with respect to these added points. Use
                      the calculator below to estimate price of one booking
                    </span>
                  </div>
                  <div>
                    {selectedAssetItem.weeklyPricing.map((item) => {
                      return (
                        <div
                          key={item.assetPricingWeeklyId}
                          className="row mt-10"
                        >
                          <div className="col-md-5 weekday-span">
                            <span>{item.weekday}</span>
                          </div>
                          <div className="col-md-5">
                            <Controls.InputControl
                              name="price"
                              other={{
                                type: "number",
                                placeholder: "Price (Rs.)",
                              }}
                              minNumber={0}
                              onChange={(e) => {
                                handleWeeklyPricingPriceChange(
                                  e.target.value,
                                  item.assetPricingWeeklyId
                                );
                              }}
                              value={item.price}
                              // error={validation.bankName}
                            ></Controls.InputControl>
                          </div>
                          <div className="col-md-2"></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="display-flex mt-20">
                <FontAwesome
                  icon={faCircleInfo}
                  iconSize={"20px"}
                  className={"info-icon-margin"}
                ></FontAwesome>

                <span className="font-italic text-sub-info">
                  Virtual calculator helps you estimate the charges that will be
                  paid by player upon booking. You can put values in minutes
                  below and calculator will auto calculate the charges with
                  values that you entered in above pricing section
                </span>
              </div>
              <div className="display-flex mt-20">
                <span className="info-icon-margin">
                  If user would play for{" "}
                </span>
                <Controls.InputControl
                  name="calctime"
                  other={{
                    type: "number",
                    placeholder: "minutes",
                  }}
                  minNumber={0}
                  style={{ width: "100px" }}
                  //   onChange={(e) => {
                  //     handleSlotsChange(
                  //       selectedAssetItem.slotsStartTime,
                  //       selectedAssetItem.slotsEndTime,
                  //       e.target.value
                  //     );
                  //   }}
                  //value={item.price}
                  // error={validation.bankName}
                ></Controls.InputControl>
                <span className="info-icon-margin">
                  minutes, You would earn
                </span>
                <Controls.InputControl
                  name="calctime"
                  other={{
                    type: "number",
                    placeholder: "Price",
                  }}
                  minNumber={0}
                  style={{ width: "100px" }}
                  //   onChange={(e) => {
                  //     handleSlotsChange(
                  //       selectedAssetItem.slotsStartTime,
                  //       selectedAssetItem.slotsEndTime,
                  //       e.target.value
                  //     );
                  //   }}
                  //value={item.price}
                  // error={validation.bankName}
                ></Controls.InputControl>
                <span className="info-icon-margin">Rs.</span>
              </div>
            </div>

            <div className="edit-section-margin">
              <label className="field-title">Advanced Settings</label>
              <Controls.CheckboxControl
                name={"multibook_checkbox"}
                label={"Allow pay later"}
                onChange={(e) => {
                  setSelectedAssetItem({
                    ...selectedAssetItem,
                    isAllowPayLater: e.target.value,
                  });
                }}
                value={selectedAssetItem.isAllowPayLater}
              ></Controls.CheckboxControl>
              <Controls.CheckboxControl
                name={"multibook_checkbox"}
                label={"Allow multiple bookings"}
                onChange={(e) => {
                  setSelectedAssetItem({
                    ...selectedAssetItem,
                    isMultiBookingAllowed: e.target.value,
                  });
                }}
                value={selectedAssetItem.isMultiBookingAllowed}
              ></Controls.CheckboxControl>
              {selectedAssetItem.isMultiBookingAllowed && (
                <div className="display-flex">
                  <FontAwesome
                    icon={faCircleInfo}
                    iconSize={"20px"}
                    className={"info-icon-margin"}
                  ></FontAwesome>

                  <span className="font-italic text-sub-info mb-10">
                    Multiple users will be able to book same slot.
                  </span>
                </div>
              )}
              {selectedAssetItem.isMultiBookingAllowed && (
                <div className="row">
                  <div className="col-md-6">
                    <Controls.InputControl
                      name="minimum_bookings"
                      label={"Minimum bookings"}
                      other={{
                        type: "number",
                        placeholder: "Minimum bookings",
                      }}
                      minNumber={0}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setSelectedAssetItem({
                          ...selectedAssetItem,
                          minimumBookingsAllowed: e.target.value,
                        });
                      }}
                      value={selectedAssetItem.minimumBookingsAllowed}
                      // error={validation.bankName}
                    ></Controls.InputControl>
                  </div>
                  <div className="col-md-6">
                    <Controls.InputControl
                      name="maximum_bookings"
                      label={"Maximum bookings"}
                      other={{
                        type: "number",
                        placeholder: "Maximum bookings",
                      }}
                      minNumber={0}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setSelectedAssetItem({
                          ...selectedAssetItem,
                          maximumBookingsAllowed: e.target.value,
                        });
                      }}
                      value={selectedAssetItem.maximumBookingsAllowed}
                      // error={validation.bankName}
                    ></Controls.InputControl>
                  </div>
                </div>
              )}
              <div className="mt-20">
                <Controls.CheckboxControl
                  name={"advance_booking_charges"}
                  label={"Include advance booking charges"}
                  onChange={(e) => {
                    setSelectedAssetItem({
                      ...selectedAssetItem,
                      isAddBookingCharges: e.target.value,
                    });
                  }}
                  value={selectedAssetItem.isAddBookingCharges}
                ></Controls.CheckboxControl>
                {selectedAssetItem.isAddBookingCharges && (
                  <div className="display-flex">
                    <FontAwesome
                      icon={faCircleInfo}
                      iconSize={"20px"}
                      className={"info-icon-margin"}
                    ></FontAwesome>

                    <span className="font-italic text-sub-info">
                      These booking charges will be included in the final bill
                      of player who is booking this asset.
                    </span>
                  </div>
                )}
                {selectedAssetItem.isAddBookingCharges && (
                  <div className="row mt-20">
                    <div className="col-md-6">
                      <Controls.InputControl
                        name="advace_booking_price"
                        label={"Price"}
                        other={{
                          type: "number",
                          placeholder: "Advance Booking price",
                        }}
                        minNumber={0}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setSelectedAssetItem({
                            ...selectedAssetItem,
                            bookingCharges: e.target.value,
                          });
                        }}
                        value={selectedAssetItem.bookingCharges}
                        // error={validation.bankName}
                      ></Controls.InputControl>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                )}
              </div>
            </div>
            <div className="edit-section-margin">
              <label className="field-title">Asset Offers</label>
              {selectedAssetItem.assetOffers &&
                selectedAssetItem.assetOffers.map((offer) => {
                  return (
                    <div
                      key={offer.assetOfferMapId}
                      className="display-flex row mb-10"
                    >
                      <div className="col-md-8 m-auto">
                        {offer.offer.assetOfferName}
                      </div>
                      <div className="col-md-4">
                        <Controls.DatePickerControl
                          id={offer.assetOfferMapId}
                          name="expirtyDate"
                          value={offer.expiryDate}
                          onChange={(e) =>
                            handleOfferExpiryDateChange(
                              e,
                              offer.assetOfferMapId
                            )
                          }
                        ></Controls.DatePickerControl>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAsset;
