import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fireEvent } from "../../../../modules/Events/eventSlice";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { URLS } from "../../../../configs/api_urls";
import { httpDelete } from "../../../../utility/request";

import Controls from "../../../../components/Controls/Controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import ModalPopup from "../../../../components/ModalPopup/modalpopup.component";
import EditAssetOffer from "./EditAssetOffer/edit-asset-offer.component";
import AssetOfferAnalytics from "./AssetOfferAnalytics/asset-offer-analytics.component";
import { setIsRenderedState } from "../../../../modules/ModalPopup/modalPopupSlice";

const AddEditAssetOffer = ({ selectedAssetOffer, cancelClick }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("settings");

  const handelArchiveClick = async () => 
  {
      await httpDelete(
        URLS.ASSET.DELETE_ASSET_OFFER.replace(
          "#ID#",
          selectedAssetOffer.assetOfferId
        )
      )
        .then((response) => {
          if (response.data == true) {
            dispatch(fireEvent({ eventName: "refresh-asset-offer-list" }));
            dispatch(setIsRenderedState(false));
            cancelClick();
          }
        })
        .catch((error) => {
          //  setFormSubmitError(
          //    error.response.data.Message || "Some error occuered!"
          //  );
        });
  }
  const handleSaveClick = () => {
    dispatch(fireEvent({ eventName: "save-asset-offer" }));
  };

  const handleArchieveButtonClick = () => {
    dispatch(setIsRenderedState(true));
  };

  useEffect(() => {
    setActiveTab("settings");
  }, [selectedAssetOffer]);

  return (
    <div className="edit-container w-100">
      <div className="display-flex">
        <Controls.ButtonControl
          text={"Cancel"}
          style={{
            width: "100px",
            margin: "10px 10px",
            backgroundColor: "gray",
          }}
          onClick={() => cancelClick()}
          //color={grey[700]}
        ></Controls.ButtonControl>
        <label className="edit-panel-title">
          {selectedAssetOffer.assetOfferId > 0
            ? "Edit Asset Offer"
            : "Add Asset Offer"}
        </label>
        <Controls.ButtonControl
          onClick={() => handleSaveClick()}
          text={"Save"}
        ></Controls.ButtonControl>
        {selectedAssetOffer.assetOfferId > 0 && (
          <Controls.ButtonControl
            onClick={handleArchieveButtonClick}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "red",
            }}
            innercomponent={
              <FontAwesomeIcon icon={faFileArchive}></FontAwesomeIcon>
            }
          ></Controls.ButtonControl>
        )}
      </div>
      <ModalPopup
        heading={"Are you sure that you want to Arhive this Offer?"}
        isConfirmButton={true}
        confirmButtonText={"Yes"}
        onConfirmClick={handelArchiveClick}
        isCancelButton={true}
        cancelButtontext={"No"}
        onCancelClick={() => dispatch(setIsRenderedState(false))}
      ></ModalPopup>
      <div>
        <Tabs
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          variant="pills"
          onSelect={(key) => setActiveTab(key)}
          id="fill-tab-example"
          className="tab-margins edit-subscription-tabs"
          fill
        >
          <Tab
            eventKey="settings"
            title="Settings"
            tabClassName={
              "settings-tab " + (activeTab != "settings" ? "inactive-tab" : "")
            }
          >
            <EditAssetOffer
              selectedAssetOffer={selectedAssetOffer}
              closeEdit={() => cancelClick()}
            ></EditAssetOffer>
          </Tab>
          <Tab
            eventKey="analytics"
            title="Analytics"
            tabClassName={
              "analytics-tab " +
              (activeTab != "analytics" ? "inactive-tab" : "")
            }
          >
            <AssetOfferAnalytics></AssetOfferAnalytics>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default AddEditAssetOffer;
