import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function AutocompleteControl({name, label, options, loading, value, defaultValue, onInputChange, onChange, style}) {
  var defaultSx = { width: 300 };
  return (
    <Autocomplete
      name={name}
      loading={loading}
      value={value}
      id="name"
      options={options}
      sx={style || defaultSx}
      defaultValue={defaultValue}
      onInputChange={(e) =>
        onInputChange({ value: e.target.value, name: e.target.name })
      }
      onChange={(event, newValue) => {
        onChange({ event: event, selectedVal: newValue });
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}