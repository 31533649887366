import React from "react";
import { Alert, InputAdornment, IconButton } from "@mui/material";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";

import withRouter from "../../../hoc/withRouter";
import { ADMIN_HOME_PAGE, SUPER_ADMIN_ROUTE } from "../../../configs/routes";

import { httpPost } from "../../../utility/request";
import { APPLICATION_TYPES } from "../../../configs/app_configs";

import { set_access_token } from "../../../hoc/helper";

import { URLS } from "../../../configs/api_urls";

import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import CustomTextField from "../../../components/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";

class LoginForm extends React.Component {
  /**
   * Set inital State
   */
  state = {
    userName: "",
    password: "",
    loginError: "",
    isSubmit: false,
    showPassword: false,
  };

  /**
   * Handle Input change
   *
   * @param {Object} e
   */
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * Handle Form Submission
   *
   * @param {Object} evt
   */
  handleSubmit = async (evt) => {
    evt.preventDefault();
    this.setState({ isSubmit: true });

    const { userName, password } = this.state;
    const { router } = this.props;

    // Basic Validations
    if (!userName || !password) return;

    // Set Loader
    this.setState({ isLoading: true });

    // Send Credentials
    try {
      const { data } = await httpPost(URLS.ADMIN.SIGN_IN, {
        userName,
        password,
        provider: "",
        applicationType: APPLICATION_TYPES.ADMIN,
        accessToken: "",
      });

      if (!data.isSuccess) {
        // Show error message

        this.setState({
          loginError: data.errorMessage,
        });
      } else {
        // Set token into cookies
        await set_access_token(data.accessToken).then(
          () => {
            const decoded = jwtDecode(data.accessToken);
            // const redirect_to =
            //   router.query.redirect_url || DEFAULT_REDIRECT_URL;
            if (decoded.application_type == "1") {
              // super admin
              router(SUPER_ADMIN_ROUTE);
            } else {
              router(ADMIN_HOME_PAGE);
            }
          },
          (err) => console.error(err)
        );
      }
    } catch (error) {
      //Handle error

      console.error(error);
      this.setState({
        loginError: "There was an issue logging you in, please try again",
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  /**
   * Show Password handle
   */
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  /**
   * Prevent default on end btn
   *
   * @param {Object} event
   */
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const {
      userName,
      password,
      isSubmit,
      loginError,
      isLoading,
      showPassword,
    } = this.state;

    return (
      <>
        <h5
          className={`font-600 text-dark-2 text-bold mt-1 mb-0 font-medium`}
          component="h6"
        >
          {" "}
          Welcome to Playnoot Admin portal
        </h5>
        <p className="text-muted-2 mt-1 d-block mb-3">
          Please sign-in to your account and start the adventure
        </p>
        {/* Show Alert if Login Fails */}
        {loginError && (
          <Alert severity="error" className="my-2">
            {loginError}
          </Alert>
        )}
        {/* Login Form */}
        <form action="" onSubmit={this.handleSubmit}>
          <CustomTextField
            error={isSubmit && !userName}
            id="userName"
            name="userName"
            onChange={this.handleChange}
            label="User Name"
            variant="outlined"
            className={`w-100 my-2 text-theme`}
            autoComplete="off"
          />
          <CustomTextField
            error={isSubmit && !password}
            id="password"
            name="password"
            onChange={this.handleChange}
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            autoComplete="off"
            className={`w-100 my-2`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <FontAwesomeIcon size="xs" icon={faEye} />
                    ) : (
                      <FontAwesomeIcon size="xs" icon={faEyeSlash} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Form.Group
            className="text-aligin-initial my-3 d-flex"
            controlId="formBasicCheckbox"
          >
            <Form.Check
              type="checkbox"
              label={<span className="text-muted"> Remember me </span>}
            />
            <span
              style={{ marginLeft: "auto" }}
              className="theme-link text-theme cursor-pointer"
              onClick={isLoading ? null : this.props.goToForgotPassword}
            >
              Forgot Password?
            </span>
          </Form.Group>
          <button
            className="theme-btn w-100 my-2 py-2"
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              "Login"
            )}
          </button>
        </form>
        <p className="text-muted-2 text-small mt-4 mb-0">
          <a
            style={{ marginRight: "10px" }}
            target="_blank"
            href="http://playnoot.in/termsandconditions"
          >
            Terms of use
          </a>
          <a target="_blank" href="http://playnoot.in/privacypolicy">
            User policies
          </a>
        </p>
      </>
    );
  }
}

export default withRouter(LoginForm);
