import { useNavigate } from "react-router-dom";
import ButtonControl from "../../../components/Controls/ButtonControl";

const SubscriptionNavigation = ({
  isCreateNewPlanVisible,
  isCreateNewOfferVisible,
  isPlansDashboardVisible,
  isSubscribersPurchaseDashboardVisible,
  isPlanOffersDashboardVisible,
  checkOverduesClick,
  subscriptionPerformenceClick,
  allSubscriberstListClick,
  createNewOfferClick,
  createNewPlanClick,
}) => {
  const navigate = useNavigate();
  return (
    <div style={{ float: "right" }}>
      <ButtonControl
        text={"Check overdues"}
        style={{ width: "180px", margin: "0px 5px", background: "gray" }}
        onClick={() => checkOverduesClick()}
      ></ButtonControl>
      <ButtonControl
        text={"Subscription peroformence"}
        style={{ width: "270px", margin: "0px 5px", background: "gray" }}
        onClick={() => subscriptionPerformenceClick()}
      ></ButtonControl>
      {isSubscribersPurchaseDashboardVisible && (
        <ButtonControl
          text={"All Subscribers"}
          style={{ width: "180px", margin: "0px 5px" }}
          onClick={() => {
            navigate("../subscribers-list");
          }}
        ></ButtonControl>
      )}
      {isPlanOffersDashboardVisible && (
        <ButtonControl
          text={"Plan Offers"}
          style={{ width: "150px", margin: "0px 5px" }}
          onClick={() => {
            navigate("../subscription-plan-offers");
          }}
        ></ButtonControl>
      )}
      {isPlansDashboardVisible && (
        <ButtonControl
          text={"Plans"}
          style={{ width: "100px", margin: "0px 5px" }}
          onClick={() => {
            navigate("../subscription-plans");
          }}
        ></ButtonControl>
      )}
      {isCreateNewOfferVisible && (
        <ButtonControl
          text={"Create new Offer"}
          style={{ width: "200px", margin: "0px 5px" }}
          onClick={() => createNewOfferClick()}
        ></ButtonControl>
      )}
      {isCreateNewPlanVisible && (
        <ButtonControl
          text={"Create new Plan"}
          style={{ width: "180px", margin: "0px 5px" }}
          onClick={() => createNewPlanClick()}
        ></ButtonControl>
      )}
    </div>
  );
};

export default SubscriptionNavigation;
