import React from "react";
import { useState } from "react";
import BookingsTable from "./BookingsTable/booking-table.component";
import { BookingFilters } from "./BookingFilters/booking-filter.component";
import { httpPost } from "../../utility/request";
import { URLS } from "../../configs/api_urls";
import Loader from "../../components/Loader";
import { Drawer } from "@mui/material";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Controls from "../../components/Controls/Controls";
import BookingDetails from "./BookingDetails/booking-details.component";

const Bookings = () => {
  const [bookingsFetchQueryDate, setBookingsFetchQueryDate] = useState(null);
  const [bookingsData, setBookingsData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isDetailsDrawOpen, setIsDetailDrawOpen] = useState(false);
  const [selectedForDeatils, setSelectedForDeatils] = React.useState(null);

  const fetchBookings = async () => {
    setIsLoading(true);
    await httpPost(URLS.GAME_CENTERS.GET_INSTANCE_BOOKINGS, {
      ...bookingsFetchQueryDate,
      pageRequest: {
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    })
      .then(({ data }) => {
        setBookingsData(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    fetchBookings();
  }, [page, rowsPerPage, bookingsFetchQueryDate]);

  return (
    <div className="flex-1 w-full h-full flex flex-col items-center justify-center">
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={isDetailsDrawOpen}
          onClose={(e) => setIsDetailDrawOpen(false)}
        >
          <Box
            sx={{
              width: "100%",
              minWidth: 360,
              bgcolor: "background.paper",
              p: 2,
              mt: 5,
            }}
          >
            <BookingDetails booking={selectedForDeatils}></BookingDetails>
          </Box>
        </Drawer>
      </React.Fragment>
      <BookingFilters onChange={setBookingsFetchQueryDate} />
      <BookingsTable
        bookings={bookingsData}
        isLoading={isLoading}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        handleDetailsClick={(e) => {
          setSelectedForDeatils(e);
          setIsDetailDrawOpen(true);
        }}
      />
      {!isLoading && (
        <div style={{ marginLeft: "auto" }} className="display-flex mt-20">
          <Pagination
            count={bookingsData.totalPages}
            page={page}
            variant="outlined"
            shape="rounded"
            showFirstButton={bookingsData.hasPreviousPage}
            showLastButton={bookingsData.hasNextPage}
            color="secondary"
            style={{ margin: "auto" }}
            onChange={(event, value) => {
              setPage(value);
            }}
          />
          <Controls.SelectControl
            name="pageSize"
            style={{ width: "100px" }}
            options={[
              { id: 10, title: "10" },
              { id: 50, title: "50" },
              { id: 100, title: "100" },
            ]}
            value={page}
            onChange={(e) => {
              setPage(1);
              setRowsPerPage(Number(e.target.value));
            }}
          ></Controls.SelectControl>
        </div>
      )}
      {/* <Loader isLoading={isLoading} /> */}
    </div>
  );
};

export default Bookings;
