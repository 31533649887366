import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { fireEvent } from "../../../../modules/Events/eventSlice";

import ButtonControl from "../../../../components/Controls/ButtonControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";

import Controls from "../../../../components/Controls/Controls";
import RichEditor from "../../../../components/RichTextEditor";

import { URLS } from "../../../../configs/api_urls";
import { httpPost, httpPut } from "../../../../utility/request";

const AddEditAssetType = ({ selectedAssetType, cancelClick }) => {

  const dispatch = useDispatch();
  
  const [selectedAssetTypeItem, setSelectedAssetTypeItem] =
    useState(selectedAssetType);

    const handleSaveClick = () => {
      if(selectedAssetType.assetTypeId > 0) 
      {
         updateAssetType(); 
      } else 
      {
         createAssetType();
      }
    }

    const updateAssetType = async () => {
      // API CAll here
      await httpPut(URLS.ASSET.UPDATE_ASSET_TYPE, 
        selectedAssetTypeItem
      )
        .then((response) => {
          if (response.data == true) {
            dispatch(fireEvent({ eventName: "refresh-asset-type-list" }));
            cancelClick();
          }
        })
        .catch((error) => {
          //  setFormSubmitError(
          //    error.response.data.Message || "Some error occuered!"
          //  );
        });
    };



    const createAssetType = async () => {
      // API CAll here
      await httpPost(URLS.ASSET.CREATE_ASSET_TYPES, selectedAssetTypeItem)
        .then((response) => {
          if (response.data == true) {
            dispatch(fireEvent({ eventName: "refresh-asset-type-list" }));
            cancelClick();
          }
        })
        .catch((error) => {
          //  setFormSubmitError(
          //    error.response.data.Message || "Some error occuered!"
          //  );
        });
    };

  const handleTypeRulesChange = (val) => 
  {
    setSelectedAssetTypeItem({
      ...selectedAssetTypeItem,
      typeRules: val,
    });
  }

  useEffect(() => {setSelectedAssetTypeItem(selectedAssetType)}, [selectedAssetType]);

  return (
    <div className="edit-container w-100">
      <div className="display-flex">
        <ButtonControl
          text={"Cancel"}
          style={{
            width: "100px",
            margin: "10px 10px",
            backgroundColor: "gray",
          }}
          onClick={() => cancelClick()}
          //color={grey[700]}
        ></ButtonControl>
        <label className="edit-panel-title">
          {selectedAssetType.assetTypeId > 0
            ? "Edit Asset Type"
            : "Add Asset Type"}
        </label>
        <ButtonControl
          onClick={() => handleSaveClick()}
          text={"Save"}
        ></ButtonControl>
        {selectedAssetType.assetTypeId > 0 && (
          <ButtonControl
            //onClick={handleArchieveButtonClick}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "red",
            }}
            innercomponent={
              <FontAwesomeIcon icon={faFileArchive}></FontAwesomeIcon>
            }
          ></ButtonControl>
        )}
      </div>
      <div style={{ marginTop: "40px", padding: "10px" }}>
        <label className="field-title">Type Name</label>

        <Controls.InputControl
          name="title"
          other={{ placeholder: "Type Name" }}
          style={{ width: "100%", marginTop: "5px" }}
          onChange={(e) =>
            setSelectedAssetTypeItem({
              ...selectedAssetTypeItem,
              assetTypeName: e.target.value,
            })
          }
          value={selectedAssetTypeItem.assetTypeName}
          //error={validation.planName}
        ></Controls.InputControl>

        <label className="field-title mt-20">Type Grace Period</label>

        <Controls.InputControl
          name="graceperiod"
          other={{ type: "number", placeholder: "Type Grace Period" }}
          minNumber={0}
          style={{ width: "100%", marginTop: "5px" }}
          onChange={(e) =>
            setSelectedAssetTypeItem({
              ...selectedAssetTypeItem,
              typeGracePeriod: e.target.value,
            })
          }
          value={selectedAssetTypeItem.typeGracePeriod}
          //error={validation.planName}
        ></Controls.InputControl>

        <label className="field-title mt-20">Type Rules</label>
        <RichEditor
          editorValue={selectedAssetTypeItem.typeRules}
          setEditorValue={(value) => handleTypeRulesChange(value)}
        ></RichEditor>
      </div>
    </div>
  );
};

export default AddEditAssetType;
