import Home from "../views/Home";
import LoginPage from "../views/Login";

import Admin from "../views/Admin";
import GameCenterHomePage from "../views/GameCentersList/game-center-list.component";
import LicenseList from "../views/License/LicenseList/license.component";
import OrderList from "../views/Orders/OrderList/orderlist.component";
import OrderDetails from "../views/Orders/OrderDetails/orderdetails.component";
import AdminUserList from "../views/AdminUsers/AdminUserList/admin-user-list.component";
import CreateAdminUser from "../views/AdminUsers/CreateAdminUser/create-admin-user.component";
import Checkout from "../components/Checkout/Checkout/checkout.component";
import AccountDetails from "../views/Account/AccountDetails/account-details.component";
import BankDetailsList from "../views/BankDetails/BankDetailsList/bank-details-list.component";

import GameCenter from "../views/Admin/Center";
//import Dashboard from "../views/Dashboard";
import Dashboard from "../views/Dashboard/dashboard.component";
import Bookings from "../views/Bookings/booking-dashboard.component";
import ErrorPage from "../views/ErrorPage";
import PaymentGateway from "../views/PaymentGateway/Gateway/payment-gateway.component";
import PaymentStatus from "../views/PaymentGateway/PaymentStatus/payment-status.component";
import SubscriptionPlanDashboard from "../views/SubscriptionPlans/subscription-plan-dashboard.component";
import SubscriptionPlanOffersDashboard from "../views/SubscriptionPlans/SubscriptionPlanOffers/subscription-plan-offers-dashboard.component";
import SubscriptionPurchaseOrderDashboard from "../views/SubscriptionPlans/SubscriptionPurchaseOrders/subscription-purchaseorder-dashboard.component";
import AssetsDashboard from "../views/Asset/Assets/assets-dashboard.component";
import AssetTypeDashboard from "../views/Asset/AssetTypes/asset-type-dashboard.component";
import AssetOfferDashboard from "../views/Asset/AssetOffers/asset-offer-dashboard.component";
import ExternalProductsDashboard from "../views/ExternalProducts/external-products-dashboard.component";
import CoachesDashboard from "../views/Coaches/coaches-dashboard.component";
import ReviewDashBoard from "../views/RatingReviews/reviews-dashboard.component";
import SettingsDashboard from "../views/Settings/settings-dashboard.component";
import InstaMojoPaymentGateway from "../views/PaymentGateway/InstaMojo/Gateway/instamojo-payment-gateway.component";
import InstaMojoPaymentStatus from "../views/PaymentGateway/InstaMojo/InstaMojoPaymentStatus/instamojo-payment-status.component";
import PaymentDashboard from "../views/Payments/payments-dashboard.component";
import PlayerDashboard from "../views/Player/player-dashboard.component";
import PhonepePaymentGateway from "../views/PaymentGateway/Phonepe/Gateway/phonepe-payment-gateway.component";
import PhonepePaymentStatus from "../views/PaymentGateway/Phonepe/PhonepePaymentStatus/phonepe-payment-status.component";
import AnnouncementsDashboard from "../views/Announcements/announcements-dashboard.component";
import BrandingsDashboard from "../views/Brandings/brandings-dashboard.component";
import CreateCenterDashboard from "../views/CreateCenter/create-center-dashboard.component";
import TaskDashboard from "../views/Tasks/task-dashboard.component";
import SuperAdminHomeComponent from "../views/SuperAdmin/super-admin-home.component";
import ApplicationDashboard from "../views/SuperAdmin/Applications/application-dashboard.component";
import AdvertisementDashboard from "../views/SuperAdmin/Advertisement/advertisement-dashboard.comonent";
import BankingDetailsDashboard from "../views/SuperAdmin/BankingDetails/banking-details-dashboard.component";
import SuperAdminAnnouncementsDashboard from "../views/SuperAdmin/Announcements/superadmin-announcements-dashboard.component";
import UserProfileSettings from "../views/UserProfile/user-profile-settings.component";
import OnlineLeadDashboard from "../views/SuperAdmin/OnlineLeads/online-lead-dashboard.component";
import SuperAdminPaymentDashboard from "../views/SuperAdmin/Payments/superadmin-payments-dashboard.component";
import SuperAdminBookingDashboard from "../views/SuperAdmin/Bookings/superadmin-booking-dashboard.component";
import PlatformChargesDashboard from "../views/SuperAdmin/PlatformCharges/platform-charges-dashboard.component";
import LicenseDashboard from "../views/SuperAdmin/License/license-dashboard.component";
import GameSessionDashboard from "../views/GameSessions/game-session-dashboard.component";

export const ROUTES = {
  DEFAULT: "/",
  LOGIN: "login",
  ADMIN: "admin",
  ERROR: "error",
  ADMIN_USERS: {
    ADMIN_USERS_LIST: "admin-users",
    ADD_ADMIN_USER: "add-admin-user",
  },
  ADMIN_LICENSE_PLANS: "license-plans",
  ADMIN_ACCOUNT: "admin-account",
  ADMIN_ORDERS: {
    ADMIN_ORDERS_LIST: "admin-orders",
    ADMIN_ORDERS_DETAILS: "admin-order-details/:adminOrderId",
  },
  ADMIN_BANK_DETAILS: "admin-bank-details",
  ADMIN_CHECKOUT: "checkout",
  ADMIN_CREATE_GAME_CENTER: "create-game-center",
  ADMIN_PAYMENTS: {
    ADMIN_PAYMENT: "admin-payment",
    ADMIN_PAYMENT_STATUS: "admin-payment-status",
  },
  INSTAMOJO_ADMIN_PAYMENTS: {
    INSTAMOJO_ADMIN_PAYMENT: "instamojo-admin-payment",
    INSTAMOJO_PAYMENT_STATUS: "instamojo-payment-status",
  },
  PHONEPE_ADMIN_PAYMENTS: {
    PHONEPE_ADMIN_PAYMENT: "phonepe-admin-payment",
    PHONEPE_PAYMENT_STATUS: "phonepe-payment-status",
  },
  ADMIN_CENTER: {
    DASHBOARD: "dashboard",
    BOOKINGS: "bookings",
    PLAYERS: "players",
    ASSETS: "assets",
    ASSET_TYPES: "asset_types",
    ASSET_OFFERS: "asset_offers",
    ADMIN_SUBSCRIPTION_PLANS: "subscription-plans",
    ADMIN_SUBSCRIPTION_PLAN_OFFERS: "subscription-plan-offers",
    ADMIN_SUBSCRIBER_LIST: "subscribers-list",
    EXTERNAL_PRODUCTS: "external_products",
    COACHES: "coaches",
    MYREVIEWS: "my_reviews",
    SETTINGS: "settings",
    PAYMENTS: "payments",
    ANNOUNCEMENTS: "announcements",
    BRANDINGS: "brandings",
    TASKS: "tasks",
    GAME_SESSIONS: "game_sessions",
  },
};

export const SUPERADMIN_ROUTES = {
  DEFAULT: "super-admin",
  LOGIN: "login",
  ADMIN: "super-admin",
  ERROR: "error",
  APPS: "apps",
  ADS: "ads"
};


export const LOGIN_PAGE = "/login";

export const ADMIN_ROUTE = "/admin";

export const SUPER_ADMIN_ROUTE = "/super-admin";

export const ADMIN_HOME_PAGE = `${ADMIN_ROUTE}`;

export const CREATE_GAME_CENTER = `${ADMIN_ROUTE}/${ROUTES.ADMIN_CREATE_GAME_CENTER}`;

export const GAME_CENTER_APP_ROUTE = "/admin/center/:applicationId";

export const APP_ROUTES = [
  { path: "/", exact: true, element: <LoginPage /> },
  { path: `/${ROUTES.LOGIN}`, exact: true, element: <LoginPage /> },
  { path: `/${ROUTES.ERROR}`, exact: true, element: <ErrorPage /> },
  {
    path: `/${ROUTES.ADMIN}/*`,
    isProtected: true,
    exact: true,
    element: <Admin />,
  },
  {
    path: `/${SUPERADMIN_ROUTES.ADMIN}/*`,
    isProtected: true,
    exact: true,
    element: <SuperAdminHomeComponent />,
  },
  {
    path: `/${ROUTES.ADMIN}/center/:applicationId/*`,
    isProtected: true,
    exact: true,
    element: <GameCenter />,
  },
];

export const APP_SUPERADMIN_ROUTES = [
  {
    path: "",
    exact: true,
    element: <ApplicationDashboard />,
  },
  {
    path: "/ads",
    exact: true,
    element: <AdvertisementDashboard />,
  },
  {
    path: "/bank-details",
    exact: true,
    element: <BankingDetailsDashboard />,
  },
  {
    path: "/announcements",
    exact: true,
    element: <SuperAdminAnnouncementsDashboard />,
  },
  {
    path: "/online-leads",
    exact: true,
    element: <OnlineLeadDashboard />,
  },
  {
    path: "/payments",
    exact: true,
    element: <SuperAdminPaymentDashboard />,
  },
  {
    path: "/user-profile",
    exact: true,
    element: <UserProfileSettings />,
  },
  {
    path: "/bookings",
    exact: true,
    element: <SuperAdminBookingDashboard />,
  },
  {
    path: "/licenses",
    exact: true,
    element: <LicenseDashboard />,
  },
];


export const APP_ADMIN_ROUTES = [
  {
    path: "",
    exact: true,
    element: <GameCenterHomePage />,
  },
  {
    path: `${ROUTES.ADMIN_ACCOUNT}`,
    element: <AccountDetails />,
  },
  {
    path: `${ROUTES.ADMIN_USERS.ADMIN_USERS_LIST}`,
    element: <AdminUserList />,
  },
  {
    path: `${ROUTES.ADMIN_USERS.ADD_ADMIN_USER}`,
    element: <CreateAdminUser />,
  },
  {
    path: `${ROUTES.ADMIN_LICENSE_PLANS}`,
    element: <LicenseList />,
  },
  {
    path: `${ROUTES.ADMIN_ORDERS.ADMIN_ORDERS_LIST}`,
    element: <OrderList />,
  },
  {
    path: `${ROUTES.ADMIN_ORDERS.ADMIN_ORDERS_DETAILS}`,
    element: <OrderDetails />,
  },
  {
    path: `${ROUTES.ADMIN_BANK_DETAILS}`,
    element: <BankDetailsList />,
  },
  {
    path: `${ROUTES.ADMIN_CHECKOUT}`,
    element: <Checkout />,
  },
  {
    path: `${ROUTES.ADMIN_CREATE_GAME_CENTER}`,
    element: <CreateCenterDashboard />,
  },
  //PAyment Gateways

  // HDFC CCAvenue gateway
  {
    path: `${ROUTES.ADMIN_PAYMENTS.ADMIN_PAYMENT}`,
    element: <PaymentGateway />,
  },
  {
    path: `${ROUTES.ADMIN_PAYMENTS.ADMIN_PAYMENT_STATUS}`,
    element: <PaymentStatus />,
  },

  //Instamojo Instamojo Payment gateway
  {
    path: `${ROUTES.INSTAMOJO_ADMIN_PAYMENTS.INSTAMOJO_ADMIN_PAYMENT}`,
    element: <InstaMojoPaymentGateway />,
  },
  {
    path: `${ROUTES.INSTAMOJO_ADMIN_PAYMENTS.INSTAMOJO_PAYMENT_STATUS}`,
    element: <InstaMojoPaymentStatus />,
  },
  {
    path: `${ROUTES.PHONEPE_ADMIN_PAYMENTS.PHONEPE_ADMIN_PAYMENT}`,
    element: <PhonepePaymentGateway />,
  },
  {
    path: `${ROUTES.PHONEPE_ADMIN_PAYMENTS.PHONEPE_PAYMENT_STATUS}`,
    element: <PhonepePaymentStatus />,
  },
  {
    path: "/user-profile",
    element: <UserProfileSettings />,
  },
];

export const APP_GAME_CENTER_ROUTES = [
  { path: "", exact: true, element: <Dashboard /> },
  { path: `${ROUTES.ADMIN_CENTER.BOOKINGS}`, element: <Bookings /> },
  {
    path: `${ROUTES.ADMIN_CENTER.PLAYERS}`,
    exact: true,
    element: <PlayerDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.ASSETS}`,
    element: <AssetsDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.ASSET_TYPES}`,
    exact: true,
    element: <AssetTypeDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.ASSET_OFFERS}`,
    exact: true,
    element: <AssetOfferDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.PAYMENTS}`,
    exact: true,
    element: <PaymentDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.ADMIN_SUBSCRIPTION_PLANS}`,
    exact: true,
    element: <SubscriptionPlanDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.ADMIN_SUBSCRIPTION_PLAN_OFFERS}`,
    exact: true,
    element: <SubscriptionPlanOffersDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.ADMIN_SUBSCRIBER_LIST}`,
    exact: true,
    element: <SubscriptionPurchaseOrderDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.EXTERNAL_PRODUCTS}`,
    exact: true,
    element: <ExternalProductsDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.COACHES}`,
    exact: true,
    element: <CoachesDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.MYREVIEWS}`,
    exact: true,
    element: <ReviewDashBoard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.SETTINGS}`,
    exact: true,
    element: <SettingsDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.ANNOUNCEMENTS}`,
    exact: true,
    element: <AnnouncementsDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.BRANDINGS}`,
    exact: true,
    element: <BrandingsDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.TASKS}`,
    exact: true,
    element: <TaskDashboard />,
  },
  {
    path: `${ROUTES.ADMIN_CENTER.GAME_SESSIONS}`,
    exact: true,
    element: <GameSessionDashboard />,
  },
];
