import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";
import GameSessionItem from "./GameSessionItem/game-session-item.component";
import ModalPopup from "../../../components/ModalPopup/modalpopup.component";

import { httpGet, httpPatch } from "../../../utility/request";
import { URLS } from "../../../configs/api_urls";
import { setIsRenderedState } from "../../../modules/ModalPopup/modalPopupSlice";
import { addAlert } from "../../../modules/App/actions";
import { fireEvent } from "../../../modules/Events/eventSlice";

const GameSessionList = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [gameSessions, setGameSessions] = useState([]);
  const [timers, setTimers] = useState([]);
  const [selectedForStop, setSelectedForStop] = useState(null);
  const [selectedForUpdate, setSelectedForUpdate] = useState(null);

  const fetchGameSessions = async () => {
    setIsLoading(true);
    const { data } = await httpGet(URLS.GAME_SESSIONS.GET_ACTIVE_GAME_SESSIONS);
    setGameSessions(data);
    setIsLoading(false);
  };

  const event = useSelector((onEvent) => {
    var eventName = onEvent.events.eventName;
    if (eventName === "refresh-game-session-list") {
      fetchGameSessions();
      dispatch(fireEvent({ eventName: "" }));
    }
    //reset event listner
  });

  const handelDeleteClick = async () => {
    await httpPatch(
      URLS.GAME_SESSIONS.STOP_GAME_SESSION.replace(
        "#GAME_SESSIONID#",
        selectedForStop.gameSessionId
      )
    )
      .then(({ data }) => {
        dispatch(
          addAlert({
            type: "success",
            message: "Session stopped !!",
          })
        ); 
        dispatch(setIsRenderedState(false));

        //clear interval
        var timerId = timers.find(
          (x) => x.gameSessionId === selectedForStop.gameSessionId
        ).timerId;
        window.clearInterval(timerId);

        // hide div manually
        var element = document.getElementById("session_" + selectedForStop.gameSessionId)
        element.style.display = "none";
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchGameSessions();
  }, []);

  useEffect(() => {
    // clear setintervals when unloading list component
    return () => {
      timers.forEach((i) => {
        window.clearInterval(i.timerId);
      });
    };
  }, [timers]);

  return (
    <Box>
      {gameSessions.length === 0 && (
        <Box sx={{ height: "100px", display: "grid", placeItems: "center" }}>
          <Typography className="text-muted">
            No active Game sessions yet.
          </Typography>
        </Box>
      )}
      <ModalPopup
        heading={"Are you sure that you want to Stop the game session #" + " ?"}
        isConfirmButton={true}
        confirmButtonText={"Yes"}
        onConfirmClick={handelDeleteClick}
        isCancelButton={true}
        cancelButtontext={"No"}
        onCancelClick={() => {
          dispatch(setIsRenderedState(false));
          setSelectedForStop(null);
        }}
      ></ModalPopup>
      <Grid container>
        {gameSessions.map((item, index) => (
          <Grid key={index} id={"session_" + item.gameSessionId} item md={3} sx={{ m: "10px" }}>
            <GameSessionItem
              session={item}
              registerTimer={(timerId, gameSessionId) => {
                timers.push({timerId: timerId, gameSessionId: gameSessionId});
                setTimers(timers);
              }}
              pauseTimer={(timerId) => window.clearInterval(timerId)}
              setSelectedStop={(session) => {
                setSelectedForStop(session);
                dispatch(setIsRenderedState(true));
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GameSessionList;
