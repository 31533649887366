import React from "react";
import { Box , Drawer} from "@mui/material";
import { useState } from "react";

import ButtonControl from "../../components/Controls/ButtonControl";
import GameSessionList from "./GameSessionList/game-session-list.component";
import AddGameSession from "./AddGameSession/add-game-session.component";

const GameSessionDashboard = () => {
    const [isAddGameSessionDrawOpen, setIsAddGameSessionDrawOpen] = useState(false);
  return (
    <Box>
      <Box sx={{ textAlign: "right", mb: 1 }}>
        <ButtonControl
          text={"Create new Session"}
          style={{ width: "220px", margin: "0px 5px", }}
          onClick={() => setIsAddGameSessionDrawOpen(true)}
        ></ButtonControl>
      </Box>
      <Box
        style={{ background: "white", padding: "15px", borderRadius: "15px" }}
      >
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={isAddGameSessionDrawOpen}
            onClose={(e) => setIsAddGameSessionDrawOpen(false)}
          >
            <Box
              sx={{
                width: "100%",
                minWidth: 360,
                bgcolor: "background.paper",
                p: 2,
                mt: 5,
              }}
            >
              <AddGameSession
                close={(e) => setIsAddGameSessionDrawOpen(false)}
              />
            </Box>
          </Drawer>
        </React.Fragment>
        <GameSessionList />
      </Box>
    </Box>
  );
};

export default GameSessionDashboard;
