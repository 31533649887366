const GuideAssetOperations = () => 
{
    return (
      <div className="edit-container">
        <div style={{ display: "inherit" }} className="operations-title">
          <div style={{ margin: "auto", textAlign: "center", marginTop: "30px" }}>
            Let people book at your facility on Playnoot app
          </div>
        </div>
        <div className="mt-50 p-20">
          <div className="row">
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Create Asset
              </h5>
              <span>
                create bookable so that people can book turfs, grounds online.
              </span>
            </div>
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Create asset offers
              </h5>
              <span>create asset offers for users to increase engagement.</span>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Check asset Analytics
              </h5>
              <span>
                See Asset booking analytics, Check montly bookings.
              </span>
            </div>
            <div className="col-md-6">
              <h5 className="text-theme font-600 cursor-pointer">
                Interactive Pricings
              </h5>
              <span>
                Create interactive pricings for assets. Create Fixed, Interveled or Weekly Pricings
              </span>
            </div>
          </div>
        </div>
      </div>
    );
}

export default GuideAssetOperations;