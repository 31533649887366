import * as React from "react";
import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Avatar, Chip } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { IconButton } from "@mui/material";
import { Drawer } from "@mui/material";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import FontAwesome from "../../../../components/Icons/fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "@mui/material";
import Controls from "../../../../components/Controls/Controls";
import TableSkeleton from "../../../../components/Skeletons/table-skeleton.component";
import PaymentDetails from "../PaymentDetails/payment-details.compoent";

import { URLS } from "../../../../configs/api_urls";
import { httpPost } from "../../../../utility/request";

import moment from "moment-timezone";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "paymentId",
    numeric: false,
    disablePadding: true,
    label: "Payment Id",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "merchanttransectionId",
    numeric: false,
    disablePadding: false,
    label: "Merchant Transection Id",
  },
  {
    id: "transectionId",
    numeric: false,
    disablePadding: false,
    label: "Transection Id",
  },
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    label: "User",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount (Rs.)",
  },
  {
    id: "type",
    numeric: true,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

const PaymentStatusColor = {
  PAYMENT_SUCCESS: "success",
  PAYMENT_ERROR: "error",
  PAYMENT_DECLINED: "error"
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all payments",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function PaymentList({ selectedDate, selectedConfirmationFilter }) {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [selectedForDeatils, setSelectedForDeatils] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isDetailsDrawOpen, setIsDetailDrawOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const fetchData = {
    pageRequest: {
      pageNumber: page,
      pageSize: rowsPerPage,
    },
    date: selectedDate == null ? null : moment(selectedDate).local().format("YYYY-MM-DDTHH:mm:ss"),
    paymnetStatus:
      selectedConfirmationFilter == "All" ? null : selectedConfirmationFilter,
    payment_for: null,
  };
  const fetchPayments = async () => {
    setIsLoading(true);
    const { data } = await httpPost(
      URLS.SUPER_ADMIN.PAYMENTS.GET_PHONEPE_PAYMENTS,
      fetchData
    );
    setPaymentData(data);
    setRows(data.items);
    setIsLoading(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.phonepePaymentId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, phonepePaymentId) => {
    const selectedIndex = selected.indexOf(phonepePaymentId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, phonepePaymentId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(parseInt(rows, 10));
    setPage(1);
  };

  const handleDetailsClick = (payment) => {
    console.log(payment);
    setSelectedForDeatils(payment);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (phonepePaymentId) =>
    selected.indexOf(phonepePaymentId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event);
    CheckOpen(event.split("_")[1]);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const CheckOpen = (phonepePaymentId) => {
    if (anchorEl !== undefined && anchorEl !== null) {
      if (anchorEl.split("_")[1] == phonepePaymentId) return true;
    }
    return false;
  };

  const getStatusString = (code) => 
  {
     if (code == "PAYMENT_SUCCESS") return "Success"
     else if (code == "PAYMENT_ERROR") return "Failed"
     else if (code == "PAYMENT_INITIATED") return "Initiated"
     else if (code == "PAYMENT_DECLINED") return "Decline";
     else if (code == "PAYMENT_PENDING") return "Pending";
     else if (code == "TIMED_OUT") return "Timed out";
  }

  useEffect(() => {
    fetchPayments();
  }, [page, rowsPerPage, selectedDate, selectedConfirmationFilter]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={isDetailsDrawOpen}
            onClose={(e) => setIsDetailDrawOpen(false)}
          >
            <Box
              sx={{
                width: "100%",
                minWidth: 360,
                bgcolor: "background.paper",
                p: 2,
                mt: 5,
              }}
            >
              <PaymentDetails payment={selectedForDeatils}></PaymentDetails>
            </Box>
          </Drawer>
        </React.Fragment>
        {isLoading && (
          <TableSkeleton
            pageNumber={page}
            pageSize={rowsPerPage}
          ></TableSkeleton>
        )}
        {!isLoading && (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.phonepePaymentId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClick(event, row.phonepePaymentId)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.phonepePaymentId}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {"#" + row.phonepePaymentId}
                      </TableCell>
                      <TableCell align="left">
                        {moment
                          .utc(row.createdOn)
                          .local()
                          .format("MMM DD, yyyy, hh:mm A")}
                      </TableCell>
                      <TableCell align="left">
                        {row.merchantTransactionId}
                      </TableCell>
                      <TableCell align="left">{row.transactionId}</TableCell>
                      <TableCell align="right" padding="none">
                        <div className="display-flex">
                          <Avatar
                            src={row.user.profilePictureUrl}
                            alt="Avatar"
                            style={{
                              borderRadius: "50%",
                              height: "50px",
                              width: "50px",
                            }}
                          ></Avatar>
                          <span className="m-auto ml-10">
                            {row.user.userName}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell align="right">{row.amount / 100}</TableCell>
                      <TableCell align="right">
                        {row.paymnent_for == 0
                          ? "License"
                          : row.paymnent_for == 1
                          ? "Subscription"
                          : "Booking"}
                      </TableCell>
                      <TableCell align="right">
                        <Chip
                          label={getStatusString(row.code)}
                          color={PaymentStatusColor[row.code]}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          id={"button_" + row.phonepePaymentId}
                          aria-label={"button_" + row.phonepePaymentId}
                          aria-controls={
                            CheckOpen(row.phonepePaymentId)
                              ? "menu_" + row.phonepePaymentId
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            CheckOpen(row.phonepePaymentId) ? "true" : undefined
                          }
                          onClick={(e) => {
                            handleMenuClick(e.currentTarget.ariaLabel);
                            e.stopPropagation();
                          }}
                        >
                          <FontAwesome
                            icon={faEllipsisV}
                            iconSize={"20px"}
                            className={"info-icon-margin"}
                          ></FontAwesome>
                        </IconButton>
                        <Menu
                          aria-label={"menu_" + row.phonepePaymentId}
                          id={"menu_" + row.phonepePaymentId}
                          anchorEl={document.getElementById(
                            "button_" + row.phonepePaymentId
                          )}
                          open={CheckOpen(row.phonepePaymentId)}
                          onClose={handleMenuClose}
                          sx={{ boxShadow: 10, color: "gray" }}
                          MenuListProps={{
                            "aria-labelledby": "button_" + row.phonepePaymentId,
                          }}
                        >
                          <MenuItem
                            onClick={(e) => {
                              handleMenuClose(e);
                              handleDetailsClick(row);
                              setIsDetailDrawOpen(true);
                            }}
                          >
                            Details
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!isLoading && (
          <div className="display-flex" style={{ padding: "10px" }}>
            <Pagination
              count={paymentData.totalPages}
              page={page}
              variant="outlined"
              shape="rounded"
              showFirstButton={paymentData.hasPreviousPage}
              showLastButton={paymentData.hasNextPage}
              color="secondary"
              style={{ marginLeft: "auto" }}
              onChange={(event, value) => {
                handleChangePage(value);
              }}
            />
            <Controls.SelectControl
              name="pageSize"
              style={{ width: "100%", marginLeft: "20px" }}
              options={[
                { id: 5, title: "5" },
                { id: 10, title: "10" },
                { id: 50, title: "50" },
              ]}
              value={rowsPerPage}
              onChange={(e) => {
                handleChangeRowsPerPage(Number(e.target.value));
              }}
            ></Controls.SelectControl>
          </div>
        )}
      </Paper>
    </Box>
  );
}
