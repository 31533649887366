import { useEffect, useState } from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  Typography,
  Box,
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FontAwesome from "../../../components/Icons/fontawesome";
import { Chip } from "@mui/material";
import {
  faCheckCircle,
  faClock,
  faShare,
} from "@fortawesome/free-solid-svg-icons";

import { URLS } from "../../../configs/api_urls";
import { httpGet } from "../../../utility/request";

import moment from "moment-timezone";

const PaymentDetails = ({ payment }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bookingDetail, setBookingDetails] = useState(null);
  const [subscriptionOrderDetail, setSubscriptionOrderDetail] = useState(null);

  const GetBookingDetails = async (bookingId) => {
    setIsLoading(true);
    await httpGet(URLS.BOOKING.GETBOOKINGDETAILSBYID.replace("#ID#", bookingId))
      .then((response) => {
        var data = response.data;
        setBookingDetails(data);
      })
      .catch((err) => {
        //err
      })
      .finally(() => setIsLoading(false));
  };

  const GetSubacriptionOrderDetails = async (subscriptionPlanUserOrderId) => {
    setIsLoading(true);
    await httpGet(
      URLS.SUBSCRIPTION_PURCHASE.GETSUBSCRIPTIONORDERBYID.replace(
        "#ID#",
        subscriptionPlanUserOrderId
      )
    )
      .then((response) => {
        var data = response.data;
        setSubscriptionOrderDetail(data);
        console.log(data);
      })
      .catch((err) => {
        //err
      })
      .finally(() => setIsLoading(false));
  };

  const callPaymentDetails = () => {
    if (payment.paymentMode === "Online") {
      if (payment.paymnent_for == 1) {
        var subscriptionPlanUserOrderId = JSON.parse(
          payment.json_object
        ).SubscriptionPlanUserOrderId;
        GetSubacriptionOrderDetails(subscriptionPlanUserOrderId);
      } else {
        var bookingId = JSON.parse(payment.json_object).BookingId;
        GetBookingDetails(bookingId);
      }
    }
  };

  useEffect(() => {
    if (payment.code == "PAYMENT_SUCCESS") {
      callPaymentDetails();
    }
  }, [payment]);

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        {payment.code == "PAYMENT_SUCCESS" && (
          <div>
            <FontAwesome
              icon={faCheckCircle}
              iconSize={"60px"}
              iconcolor={"green"}
            ></FontAwesome>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Payment Successful
            </Typography>
          </div>
        )}
        {payment.code == "PAYMENT_INITIATED" && (
          <div>
            <FontAwesome
              icon={faClock}
              iconSize={"60px"}
              iconcolor={"gray"}
            ></FontAwesome>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Payment Pending
            </Typography>
          </div>
        )}
        {payment.status == "Initiated" && (
          <div>
            <FontAwesome
              icon={faShare}
              iconSize={"60px"}
              iconcolor={"gray"}
            ></FontAwesome>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Payment Initialted
            </Typography>
          </div>
        )}
      </div>
      <div className="mt-20">
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Payment Details
        </Typography>
        <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Payment Id</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {"#" + payment.phonepePaymentId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Payment Time</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {moment
                .utc(payment.createdOn)
                .local()
                .format("MMM DD, yyyy, hh:mm A")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Transection Id</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {payment.transactionId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Amount</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {payment.amount / 100 + " Rs."}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Payment For</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {payment.paymnent_for == 2 ? "Booking" : "Subscription"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Payment For</TableCell>
            <TableCell
              sx={{ float: "right", border: "none", maxWidth: "300px" }}
            >
              {payment.message}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>Payment Mode</TableCell>
            <TableCell sx={{ float: "right", border: "none" }}>
              {payment.paymentMode}
            </TableCell>
          </TableRow>
          {payment.code === "PAYMENT_SUCCESS" && payment.paymentMode === 'Online  ' && (
            <TableRow>
              <TableCell sx={{ border: "none" }}>Settlement Status</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                <Box>
                  {payment.paymentSettlementId == null ? (
                    <Chip label={"Pending"} color={"warning"} />
                  ) : (
                    <Box className="display-flex">
                      <Chip label={"Logged"} color={"success"} />
                      <Typography># {payment.paymentSettlementId}</Typography>
                    </Box>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </div>
      {payment.user !== null && (
        <div className="mt-20">
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            User Details
          </Typography>
          <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
            <TableRow>
              <TableCell sx={{ border: "none" }}>App User Name</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {payment.user.userName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Full Name</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {payment.user.firstName || ""}
                {payment.user.middleName || ""}
                {payment.user.lastName || ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Mobile Number</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {payment.user.primaryPhoneNumber}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: "none" }}>Email Id</TableCell>
              <TableCell sx={{ float: "right", border: "none" }}>
                {payment.user.emailId}
              </TableCell>
            </TableRow>
          </Table>
        </div>
      )}
      {payment.paymnent_for == 2 && !isLoading && bookingDetail != null && (
        <div className="mt-20">
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Booking Details
          </Typography>
          <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
            <TableBody>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Booking Id</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  #{bookingDetail.bookingId}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Booked Asset</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {bookingDetail.asset.assetName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Booking Date</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {moment
                    .utc(bookingDetail.bookingDate)
                    .local()
                    .format("MMM DD, yyyy")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Slot Bill</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {bookingDetail.slotTotalPrice + " Rs."}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Products Bill</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {bookingDetail.totalExternalProductBill + " Rs."}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Booking charges</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {bookingDetail.bookingCharges + " Rs."}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Platform charges</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {bookingDetail.platFormCharges + " Rs."}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Slots</TableCell>
                <TableCell
                  sx={{ float: "right", border: "none", maxWidth: "400px" }}
                >
                  {bookingDetail.bookingTimeSlots.map((slot, index) => (
                    <Chip
                      variant="outlined"
                      key={`slot-${index}`}
                      label={slot.timeSlot.slotDisplayText}
                      style={{
                        height: "40px",
                        margin: "5px 2px",
                        fontSize: "16px",
                      }}
                      clickable
                      //handleClick={handleDisableSlot}
                    />
                  ))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </div>
      )}
      {payment.paymnent_for == 1 &&
        !isLoading &&
        subscriptionOrderDetail != null && (
          <div className="mt-20">
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Subscription Details
            </Typography>
            <Table sx={{ minWidth: "500px", fontFamily: "Kanit" }}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Subscription Id</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  #{subscriptionOrderDetail.subscriptionPlanUserOrderId}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Plan</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {subscriptionOrderDetail.subscriptionPlan.planName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Purchase Date</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {moment
                    .utc(subscriptionOrderDetail.orderedDateTime)
                    .local()
                    .format("MMM DD, yyyy")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Status</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {subscriptionOrderDetail.status}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border: "none" }}>Expiry Date</TableCell>
                <TableCell sx={{ float: "right", border: "none" }}>
                  {moment
                    .utc(subscriptionOrderDetail.planExpiryDateTime)
                    .local()
                    .format("MMM DD, yyyy")}
                </TableCell>
              </TableRow>
            </Table>
          </div>
        )}
    </div>
  );
};

export default PaymentDetails;
