import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Controls from "../../../../components/Controls/Controls";
import GoogleMap from "../../../../components/GoogleMap/google-map.component";
import ImageProcessor from "../../../../components/ImageProcessor/image-processor.component";
import { CircularProgress } from "@mui/material";

import { URLS } from "../../../../configs/api_urls";
import { httpGet, httpPost, httpPut } from "../../../../utility/request";
import { fireEvent } from "../../../../modules/Events/eventSlice";

const AddEditAdvertisement = ({ selectedAd, cancelClick }) => {
  const dispatch = useDispatch();
  const [selectedAdItem, setSelectedAdItem] = useState(selectedAd);
  const [instanceName, setInstanceName] = useState("");
  const [instanceList, setInstanceList] = useState([]);
  const [stdGameList, setStdGameList] = useState([]);
  const [adUrl, setAdUrl] = useState("");

  const [isLoadingCenterList, setIsLoadingCenterList] = useState(true);
  const [isLoadingCenterDetails, setIsLoadingCenterDetails] = useState(false);
  const [isLoadingStdGameList, setIsLoadingStdGameList] = useState(false);

  var initialFilterValue = {
    searchString: "",
    pageRequest: {
      pageNumber: 1,
      pageSize: 100,
    },
    userLocation: {
      latitude: selectedAdItem.latitude,
      longitude: selectedAdItem.longitude,
      distance: selectedAdItem.visibiltyDistance,
    },
    priceLimits: {
      minimumPrice: 0,
      maximumPrice: 0,
    },
    standardGames: [],
    availableOn: null,
    sortBy: 1,
  };

  const [filter, setFilter] = useState(initialFilterValue);

  const filterPriceOption = [
    { id: 0, title: "None", minimumPrice: 0, maximumPrice: 0 },
    { id: 1, title: "50 - 100", minimumPrice: 50, maximumPrice: 100 },
    { id: 2, title: "100 - 300", minimumPrice: 100, maximumPrice: 300 },
    { id: 3, title: "300 - 500", minimumPrice: 300, maximumPrice: 500 },
    { id: 4, title: "500 - 1000", minimumPrice: 500, maximumPrice: 1000 },
    { id: 5, title: "1000 - 100000", minimumPrice: 1000, maximumPrice: 100000 },
  ];

  const advertisementTypeOption = [
    { id: 0, title: "Flash Poster" },
    { id: 1, title: "Link to Center" },
    { id: 2, title: "Open Filter" },
    { id: 3, title: "Link to Url" },
  ];

  const handleDeleteButtonClick = () => {};

  const createAdvertisement = async () => {
    await httpPost(URLS.ADVERTISEMENT.CREATE_ADVERTISEMENT, {
      ...selectedAdItem,
    })
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-advertisement-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        cancelClick();
      });
  };

  const updateAdvertisement = async () => {
    debugger;
    await httpPut(URLS.ADVERTISEMENT.UPDATE_ADVERTISEMENT, {
      ...selectedAdItem,
    })
      .then((response) => {
        if (response.data == true) {
          dispatch(fireEvent({ eventName: "refresh-advertisement-list" }));
        }
      })
      .catch((error) => {
        //  setFormSubmitError(
        //    error.response.data.Message || "Some error occuered!"
        //  );
      })
      .finally(() => {
        cancelClick();
      });
  };

  const getStandardGames = async () => {
    setIsLoadingStdGameList(true);
    await httpGet(URLS.MASTER.GET_STANDARD_GAMES)
      .then(({ data }) => {
        setStdGameList(data);
      })
      .finally(() => {
        setIsLoadingStdGameList(false);
      });
  };

  const getCenterNames = async (centerName) => {
    setIsLoadingCenterList(true);
    setInstanceName(centerName);
    if (centerName.length <= 3) return;
    await httpGet(
      URLS.APPLICATION_INSTANCE.SEARCH_APPLICATION_INSTANCE_BY_NAME.replace(
        "#NAME#",
        centerName
      )
    )
      .then(({ data }) => {
        var autoCompleteList = data.map((i) => ({
          ...i,
          id: i.applicationInstanceId,
          label: i.applicationInstanceName + " by " + i.applicationName,
        }));
        setInstanceList(autoCompleteList);
      })
      .finally(() => {
        setIsLoadingCenterList(false);
      });
  };

  const getCenterDetails = async (selectedAppInstance) => {
    setIsLoadingCenterDetails(true);
    await httpGet(
      URLS.APPLICATION_INSTANCE.GET_APP_INSTANCE_MOBILE.replace(
        "#ID#",
        selectedAppInstance.applicationInstanceId
      )
    )
      .then(({ data }) => {
        setSelectedAdItem({
          ...selectedAdItem,
          clickEventPostJsonData: JSON.stringify(data),
        });
      })
      .finally(() => {
        setIsLoadingCenterDetails(false);
      });
  };

  const handleSaveClick = () => {
    if (selectedAdItem.advertisementId > 0) {
      updateAdvertisement();
    } else {
      createAdvertisement();
    }
  };

  const updateAdvertisementType = (adType) => {
    var jsonData = {};

    if (adType == 0) {
      jsonData = "";
    } else if (adType == 1) {
      setInstanceName("");
      setInstanceList([]);
    } else if (adType == 2) {
      getStandardGames();
    } else if (adType == 3) {
      jsonData = JSON.stringify({ link: adUrl });
    }

    setSelectedAdItem({
      ...selectedAdItem,
      advertisementType: adType,
      clickEventPostJsonData: jsonData,
    });
  };

  const handleStandardGameChange = (e) => {
    const { name, value } = e.target;
    if (value === true) {
      var newList = filter.standardGames;
      newList.push(name);
      setFilter({ ...filter, standardGames: newList });
    } else {
      var newList = filter.standardGames.filter((g) => g !== name);
      setFilter({ ...filter, standardGames: newList });
    }
    updateFilterJson();
  };

  // write all functions to update filter props
  const updateFilterJson = () => {
    setSelectedAdItem({
      ...selectedAdItem,
      clickEventPostJsonData: JSON.stringify(filter),
    });
  };

  const updateAdLocation = (latitude, longitude) => {
     if (selectedAdItem.advertisementType === 2) {
       var newFilter = {
         ...filter,
         userLocation: {
           latitude: latitude,
           longitude: longitude,
           distance: selectedAdItem.visibiltyDistance,
         },
       };
       setSelectedAdItem({
         ...selectedAdItem,
         latitude: latitude,
         longitude: longitude,
         clickEventPostJsonData: JSON.stringify(newFilter),
       });
     } else {
      setSelectedAdItem({
        ...selectedAd,
        latitude: latitude,
        longitude: longitude,
      });
     }
  }

  const getPriceRangeValue = () => {
    var filterOption = filterPriceOption.find(
      (f) =>
        f.minimumPrice == filter.priceLimits.minimumPrice &&
        f.maximumPrice == filter.priceLimits.maximumPrice
    );

    if (filterOption == undefined) return 0;

    return filterOption.id;
  };

  const handleFilterPriceChange = (val) => {
    var filterOption = filterPriceOption.find((f) => f.id === val);
    var newPriceLimit = {minimumPrice: filterOption.minimumPrice, maximumPrice: filterOption.maximumPrice}
    var newFilter = { ...filter, priceLimits: newPriceLimit };
    setSelectedAdItem({...selectedAdItem, clickEventPostJsonData: JSON.stringify(newFilter)});
    setFilter(newFilter); 
  };

    const handleFilterDateChange = (date) => {
      var newFilter = { ...filter, availableOn: date };
      setSelectedAdItem({
        ...selectedAdItem,
        clickEventPostJsonData: JSON.stringify(newFilter),
      });
      setFilter(newFilter);
    };

  useEffect(() => {
    setSelectedAdItem(selectedAd);
    if (selectedAd.advertisementType == 1 && selectedAd.advertisementId > 0) {
      var center = JSON.parse(selectedAd.clickEventPostJsonData);
      setInstanceName(center.applicationInstanceName);
    } else if (selectedAd.advertisementType == 2) {
      getStandardGames();
      var filter = JSON.parse(selectedAd.clickEventPostJsonData);
      setFilter(filter);
    } else if (
      selectedAd.advertisementType == 3 &&
      selectedAd.advertisementId > 0
    ) {
      var linkObj = JSON.parse(selectedAd.clickEventPostJsonData);
      var link = linkObj.link;
      setAdUrl(link);
    }
  }, [selectedAd]);

  return (
    <div className="edit-container w-100">
      <div className="display-flex">
        <Controls.ButtonControl
          text={"Cancel"}
          style={{
            width: "100px",
            margin: "10px 10px",
            backgroundColor: "gray",
          }}
          onClick={() => cancelClick()}
          //color={grey[700]}
        ></Controls.ButtonControl>
        <label className="edit-panel-title">
          {selectedAd.advertisementId > 0 ? "Edit Ad" : "Create Ad"}
        </label>
        <Controls.ButtonControl
          onClick={() => handleSaveClick()}
          text={"Save"}
        ></Controls.ButtonControl>
        {selectedAd.advertisementId > 0 && (
          <Controls.ButtonControl
            onClick={handleDeleteButtonClick}
            style={{
              width: "40px",
              margin: "10px 5px",
              backgroundColor: "red",
            }}
            innercomponent={<FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>}
          ></Controls.ButtonControl>
        )}
      </div>
      <div className="mt-50" style={{ padding: "10px" }}>
        <div className="display-flex">
          <div style={{ width: "320px", height: "180px", margin: "auto" }}>
            <ImageProcessor
              alt="asset_image"
              style={{
                borderRadius: "25px",
                width: "320px",
                height: "180px",
                objectFit: "cover",
              }}
              imageUrl={selectedAdItem.bannerUrl}
              uploadUrl={URLS.FILES.ADVERTISEMENT_IMAGE}
              setImageData={(e) => {
                setSelectedAdItem({
                  ...selectedAdItem,
                  bannerUrl: e,
                });
              }}
            ></ImageProcessor>
          </div>
          {/* <span className="upload-image-title ">Upload Image</span> */}
        </div>
        <div className="edit-section-margin" style={{ display: "grid" }}>
          <label className="field-title">Select seller platform</label>
          <Controls.SelectControl
            name="isVeg"
            style={{ width: "100%", marginTop: "5px" }}
            options={advertisementTypeOption}
            value={selectedAdItem.advertisementType}
            onChange={(e) => updateAdvertisementType(e.target.value)}
          ></Controls.SelectControl>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Select location</label>
          <div className="mt-10">
            <GoogleMap
              latitude={parseFloat(selectedAdItem.latitude)}
              longitude={parseFloat(selectedAdItem.longitude)}
              zoomVal={15}
              onClickCallback={({ latitude, longitude }) => {
                updateAdLocation(latitude, longitude);
              }}
            ></GoogleMap>
          </div>
        </div>
        <div className="edit-section-margin">
          <label className="field-title">Visibility Distance</label>
          <Controls.InputControl
            name="visibiltyDistance"
            other={{ type: "number", placeholder: "Enter visibility distance" }}
            minNumber={0}
            style={{ width: "100%", marginTop: "5px" }}
            onChange={(e) =>
              setSelectedAdItem({
                ...selectedAdItem,
                visibiltyDistance: e.target.value,
              })
            }
            value={selectedAdItem.visibiltyDistance}
            //error={validation.planName}
          ></Controls.InputControl>
        </div>
        {selectedAdItem.advertisementType == 1 && (
          <div className="edit-section-margin">
            <label className="field-title">Center Name</label>
            <Controls.AutocompleteControl
              name={"centerName"}
              options={instanceList}
              style={{ width: "100%", marginTop: "5px" }}
              onInputChange={(e) => {
                getCenterNames(e.value);
              }}
              label={instanceName}
              onChange={({ selectedVal }) => getCenterDetails(selectedVal)}
              value={instanceName}
              //error={validation.planName}
            ></Controls.AutocompleteControl>
          </div>
        )}
        {selectedAdItem.advertisementType == 2 && isLoadingStdGameList && (
          <CircularProgress></CircularProgress>
        )}
        {selectedAdItem.advertisementType == 2 && !isLoadingStdGameList && (
          <div className="mt-20 row">
            <label className="field-title">Standard Games</label>
            {stdGameList.map((game) => {
              return (
                <div className="col-md-3" key={game.standardGameId}>
                  <Controls.CheckboxControl
                    name={game.standardGameId}
                    label={game.standardGameName}
                    onChange={(e) => {
                      handleStandardGameChange(e);
                    }}
                    value={filter.standardGames.includes(game.standardGameId)}
                  />
                </div>
              );
            })}
            <div className="mt-20 display-grid">
              <label className="field-title">Select seller platform</label>
              <Controls.SelectControl
                name="isVeg"
                style={{ width: "100%", marginTop: "5px" }}
                options={filterPriceOption}
                value={getPriceRangeValue()}
                onChange={(e) => handleFilterPriceChange(e.target.value)}
              ></Controls.SelectControl>
            </div>
            <div className="mt-20 display-grid">
              <label className="field-title">Available On</label>
              <Controls.DatePickerControl
                id={"brandExpiry"}
                name="expirtyDate"
                value={filter.availableOn}
                style={{ width: "100%", marginTop: "10px" }}
                onChange={(date) => handleFilterDateChange(date)}
              ></Controls.DatePickerControl>
            </div>
          </div>
        )}
        {selectedAdItem.advertisementType == 3 && (
          <div className="edit-section-margin">
            <label className="field-title">Url link</label>
            <Controls.InputControl
              name="webSiteLink"
              other={{ placeholder: "Enter Url" }}
              style={{ width: "100%", marginTop: "5px" }}
              onChange={(e) => {
                var text = e.target.value;
                setSelectedAdItem({
                  ...selectedAdItem,
                  clickEventPostJsonData: JSON.stringify({ link: text }),
                });
                setAdUrl(text);
              }}
              value={adUrl}
              //error={validation.planName}
            ></Controls.InputControl>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddEditAdvertisement;
