import React from 'react'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from '@mui/material';

const DatePickerControl = (props) => {

    const { name, label, value, style, disabled, onChange } = props


const defautlSx = { padding: "15px 5px 15px" };
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          label={label}
          value={value}
          disabled={disabled}
          name={name}
          inputFormat="MM/DD/YYYY"
          sx={defautlSx || style}
          onChange={onChange}
          renderInput={(params) => <TextField size="medium" {...params} />}
        />
      </LocalizationProvider>
    );
}

export default DatePickerControl;