import { useNavigate } from "react-router-dom";
import ButtonControl from "../../../components/Controls/ButtonControl";

const AssetNavigation = ({
  isCreateAssetVisible,
  isCreateNewAssetOfferVisible,
  isCreateNewAssetTypeVisible,
  isAssetDashboardVisible,
  isAssetOffersDashboardVisible,
  isAssetTypeDashboardVisible,
  checkBookingPerformence,
  checkBookingEarningPerformence,
  createNewAssetOfferClick,
  createNewAssetTypeClick,
  createNewAssetClick,
}) => {
  const navigate = useNavigate();
  return (
    <div style={{ float: "right" }}>
      <ButtonControl
        text={"Booking Performence"}
        style={{ width: "220px", margin: "0px 5px", background: "gray" }}
        onClick={() => checkBookingPerformence()}
      ></ButtonControl>
      <ButtonControl
        text={"Earning Peroformence"}
        style={{ width: "240px", margin: "0px 5px", background: "gray" }}
        onClick={() => checkBookingEarningPerformence()}
      ></ButtonControl>
      {isAssetOffersDashboardVisible && (
        <ButtonControl
          text={"Asset Offers"}
          style={{ width: "150px", margin: "0px 5px" }}
          onClick={() => {
            navigate("../asset_offers");
          }}
        ></ButtonControl>
      )}
      {isAssetTypeDashboardVisible && (
        <ButtonControl
          text={"Asset Types"}
          style={{ width: "150px", margin: "0px 5px" }}
          onClick={() => {
            navigate("../asset_types");
          }}
        ></ButtonControl>
      )}
      {isAssetDashboardVisible && (
        <ButtonControl
          text={"Assets"}
          style={{ width: "100px", margin: "0px 5px" }}
          onClick={() => {
            navigate("../assets");
          }}
        ></ButtonControl>
      )}
      {isCreateNewAssetOfferVisible && (
        <ButtonControl
          text={"Create new Offer"}
          style={{ width: "200px", margin: "0px 5px" }}
          onClick={() => createNewAssetOfferClick()}
        ></ButtonControl>
      )}
      {isCreateNewAssetTypeVisible && (
        <ButtonControl
          text={"Create new Type"}
          style={{ width: "200px", margin: "0px 5px" }}
          onClick={() => createNewAssetTypeClick()}
        ></ButtonControl>
      )}
      {isCreateAssetVisible && (
        <ButtonControl
          text={"Create new Asset"}
          style={{ width: "200px", margin: "0px 5px" }}
          onClick={() => {createNewAssetClick()}}
        ></ButtonControl>
      )}
    </div>
  );
};

export default AssetNavigation;
